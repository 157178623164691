/* Base layout for smallest width */
.grid-container {
    display: grid;
    grid-template-columns: repeat(1, 350px);
  }
  
  /* Layout for widths greater than 700px */
  @media (min-width: 701px) {
    .grid-container {
      grid-template-columns: repeat(2, 350px);
    }
  }
  
  /* Layout for widths greater than 1050px */
  @media (min-width: 1051px) {
    .grid-container {
      grid-template-columns: repeat(3, 350px);
    }
  }
  
  /* Layout for widths greater than 1400px */
  @media (min-width: 1401px) {
    .grid-container {
      grid-template-columns: repeat(4, 350px);
    }
  }
  