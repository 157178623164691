.background-image{
    background-image: url('./Assets/PowerWashing.jpg');
    height: 100vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    /* color: black; */
    font-size: 4rem;
    font-weight: bold;
    text-shadow: 2px 2px 4px #000000;
    opacity: 0.9;
    
}
.title{
    /* position: absolute; */
    left:0;
    max-width: 700px;
    /* background-color: #000000; */
    width: fit-content;
    text-align:left;
    text-wrap: wrap;
    color: white;



}
.title-text{
    padding-left: 50px;
    font-family: "PT Serif", serif;
    font-weight: 700;
  /* font-style: italic; */
    /* color: ; */
    font-size: 80px;
}
html {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
  }
  html::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }