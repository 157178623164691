.red{
    background-color: lightcoral;
}
.green{
    background-color: lightblue;
}
.parent {
    height:200px;
    width:500px
}
.childA{
  float:left;
  /* width:30%; */
  background-color:#0f0;
  height:200px
}
.childB {
  float:right;
  /* width:70%; */
  background-color:#f00;
  height:200px
}
/* Carousel.css */
.carousel-container {
  width: 80%;
  margin: auto;
}

.carousel-image {
  width: 100%;
  height: auto;
}
/* Carousel.css */
.carousel-container {
  width: 80%;
  margin: auto;
  position: relative;
}

.carousel-image {
  width: 100%;
  height: auto;
}

.carousel-controls {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.carousel-button {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 10px 20px;
  margin: 0 5px;
  cursor: pointer;
}

.carousel-button:hover {
  background-color: #0056b3;
}
.Slide-container{
  display: flex;
  justify-content: center;
  flex-direction: row;
}