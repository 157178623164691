.BIGcontainer {
    height: fit-content;
    min-width: 100%;
    background-color: #F2F2F2;
    padding-top: 100px;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    padding-top: 70px;
}
.SERVICETITLE{
    text-align: center;
    color: #973131;
}
.SERVICEtitle {
    text-align: center;
    /* color: #333; */
    margin-bottom: 20px;
}

.image {
    width: 100%;
    max-width: 800px;
    height: 550px;
    display: block;
    margin: 0 auto 20px;
    border-radius: 8px;
}

.paragraph {
    font-size: 18px;
    margin-bottom: 20px;
    text-align: center;
    font-weight: bold;
}

.subTitle {
    color: #FFF;
    margin-bottom: 10px;
    text-align: center;
}

.list {
    list-style-type: disc;
    padding-left: 20px;
    margin-bottom: 20px;
    color: #FFF;
}

.cardsContainer{
    display: flex !important;
    flex-direction: row  !important;
    align-items: center !important;
    gap: 20px !important;
    margin: 20px !important;
    justify-content: center !important;
    

}
.card{
    background-color: #973131 !important;
    opacity: 0.9 !important;
    border-radius: 8px !important;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1) !important;
    padding: 20px !important;
    margin: 20px 0 !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100% !important;
    max-width: 500px !important;
    height: auto !important;
    transition: all 0.3s ease-in-out !important;
    width: 100% !important;
    height: 300px !important;
}
@media screen and (max-width: 768px) {
    .cardsContainer{
        flex-direction: column !important;
        align-items: center !important;
    }
    .card{
        width: 100% !important;
    }
    
}