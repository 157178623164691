.Button{
    
    border: none;
    color: white;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 23px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 12px;
    text-align: left !important;
    /* border-left: 10px solid white;    */
    background-color: transparent;
    width:70%;
}